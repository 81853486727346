@font-face {
  font-family: "open-sans";
  src: local("open-sans"), url(./assets/fonts/open-sans.ttf) format("truetype");
  font-family: "open-sans";
  src: local("open-sans"), url(./assets/fonts/open-sans.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "open-sans-bold";
  src: local("open-sans"),
    url(./assets/fonts/open-sans-bold.ttf) format("truetype");
  font-family: "open-sans-bold";
  src: local("open-sans"),
    url(./assets/fonts/open-sans-bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "open-sans-extra";
  src: local("open-sans"),
    url(./assets/fonts/open-sans-extrabold.ttf) format("truetype");
  font-family: "open-sans-extra";
  src: local("open-sans"),
    url(./assets/fonts/open-sans-extrabold.ttf) format("truetype");
  font-display: swap;
}
@import "./Stepper.css";
@import "./Stepper.css";

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 94vh;
}
.App {
  position: relative;
  width: 1252px;
  /* min-width: 100%; */
  min-height: calc(100vh - 80px - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.options-holder {
  display: flex;
}

.pajzsok {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  max-width: calc(100px + (288px * 4));
  margin: 0 auto;
  margin-bottom: 70px;
}

.element {
  position: relative;
  /* max-width: calc((100px + (288px * 4)) / 4); */
  width: calc((100% - (30px * 3)) / 4);
}

/*.pajzs-shapes img {
  width: 300px;
  height: 300px;
} */

.pajzs-shape {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.pajzs {
  width: 288px;
  min-width: 100%;
}

.shapes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 30px;
  min-width: 100%;
  max-width: 1092px;
  width: calc((100% - (30px * 3)) / 4);
  margin: 0 auto;
  margin-bottom: 70px;

  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  max-width: calc(100px + (288px * 4));
  margin: 0 auto;
  margin-bottom: 150px; */
}

.motive-shapes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 30px;
  width: 100%;
  max-width: calc(100px + (288px * 4));
  margin: 0 auto;
  margin-bottom: 70px;
}

.division {
  position: relative;
  flex: none;
  width: 20%;
  height: calc(100vw / 8);
  margin-bottom: 20px;
}

.selected {
  position: relative;
  flex: none;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.select-button {
  height: 40px;
  width: 127px;
  border-radius: 0px;
  padding: 8px 14px 8px 14px;
  background-color: #ba5b2d;
  border: none;
  text-transform: uppercase;
  color: white;
  font-family: "open-sans-bold";
  font-family: "open-sans-bold";

  /* position: absolute;
  bottom: 12px;
  right: 60px; */
}

.select-button:disabled {
  background-color: #cdc9c6;
}

.motives .holder .sticky-button-holder .select-button {
  width: 181px;
}

.back-button {
  font-family: "open-sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a513f;

  height: 40px;
  width: 160px;
  border-radius: 0px;
  padding: 8px 14px 8px 0px;
  background-color: white;
  border: none;
  text-transform: uppercase;
  font-family: "open-sans-bold";
  font-family: "open-sans-bold";

  /* position: absolute;
  left: 20px;
  bottom: 12px; */
}

.styles {
  display: flex;
  flex-direction: column;
}

.inner-transform {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.inner-transform img {
  width: 24px;
  height: 24px;
}

.lb-home {
  text-align: center;
  margin-top: 40px;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
 
}

.lb-content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(94vh - 150px);
}

.lb-content-section .scan-button p {
  font-size: 14px;
  line-height: 24px;
  text-align: start;
  width: 335px;
  margin-bottom: 8px;
}
.lb-content-section .manual-input p {
  font-size: 14px;
  line-height: 24px;
  text-align: start;
  width: 335px;
  margin-top: 0;
  margin-bottom: 8px;
}

.lb-footer {
  display: flex;
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 10px;
  max-width: 350px;
  margin: 0 auto;
  justify-content: center;
  gap: 16px;
}

.lb-footer:hover {
  cursor: pointer;
}

.gotoscan-button {
  height: 48px;
  width: 393px;
  border-radius: 0px;
  padding: 11px 0px 11px 0px;
  margin-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 0px;
  gap: 10px;
  background-color: #00d5ff;
  border: 1px solid #00d5ff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  color: white;
  font-family: "segoe-bold";
  font-family: "segoe-bold";
  cursor: pointer;
}

.address-input {
  height: 48px;
  width: 393px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 0px 13px 16px;
  gap: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 213, 255, 0.3);
  text-transform: uppercase;
  font-style: italic;
  font-family: "segoe";
  font-family: "segoe";
}

.address-input:focus-visible {
  outline: none;
}

.uppercase {
  text-transform: uppercase;
}

.page-title-home {
  font-family: "open-sans";
  font-family: "open-sans";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: #ba5b2d;
  font-family: "open-sans-extra";
  font-family: "open-sans-extra";
}

.page-title-pajzsok {
  font-family: "open-sans";
  font-family: "open-sans";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: #ba5b2d;
  font-family: "open-sans-extra";
  font-family: "open-sans-extra";
  margin-top: 40px;
}

.home-texts {
  font-family: "open-sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 8px 20px;
  color: #5a513f;
}

.scan-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "segoe";
  font-family: "segoe";
}

.manual-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "open-sans";
  font-family: "open-sans";
}

.lang-select {
  border: none;
}

.header {
  max-width: 1252px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 20px 0 20px;
}

.header .logo {
  height: 34px;
  width: 156px;
}

.langpage-footer {
  /* position: absolute; */
  width: 100%;
  /* bottom: 90px; */
  /* margin-bottom: 90px; */
  /* margin-bottom: 8px; */
}

.footer-holder {
  width: 100vw;
  margin-bottom: 200px;
  bottom: 200px;
}

.footer {
  /* margin-bottom: 100px; */
  text-align: center;
  width: 100%;
  font-family: "segoe";
  font-family: "segoe";
  font-size: 12px;
}

.footer p {
  font-size: 12px;
  color: #ffffffcf;
}
.footer .poweredBy {
  margin-top: 0;
  margin-bottom: 8px;
  font-family: "open-sans";
  font-family: "open-sans";
}
.footer .poweredBy .ccLink {
  color: #ba5b2d;
  text-decoration: none;
  font-family: "open-sans-bold";
  font-family: "open-sans-bold";
}

.footer .poweredBy span {
  color: #ba5b2d;
  text-decoration: none;
  font-family: "open-sans-bold";
}

.page-title {
  font-family: "open-sans-extra";
  font-family: "open-sans-extra";
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  color: #ba5b2d;
  text-transform: uppercase;
  margin-top: 64px;
}

.language-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 64px;
  margin-bottom: 32px; */
  height: 100%;
  overflow-x: hidden;
  min-height: 94vh;
  justify-content: space-between;
}

.language-page .langpage-footer {
  bottom: 0!important;
}

.language-buttons-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 180px;
}

.language-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px 8px 8px;
  gap: 4px;
  width: 394px;
  height: 48px;

  background-color: #5a513f;
  border: none;
  font-family: "open-sans";
  font-family: "open-sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}

.css-b62m3t-container .css-13cymwt-control {
  border: none !important;
  background-color: transparent !important;
}

.css-b62m3t-container .css-t3ipsp-control {
  border: none !important;
  background-color: transparent !important;
}

/* //input */
.css-13cymwt-control .css-qbdosj-Input {
  display: none !important;
}

.css-t3ipsp-control .css-qbdosj-Input {
  display: none !important;
}
/* 
//image */
.css-13cymwt-control .css-1fdsijx-ValueContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-13cymwt-control .css-hlgwow {
  padding: 0 !important;
}

.css-t3ipsp-control:hover {
  border-color: transparent !important;
  border: none !important;
}

.css-t3ipsp-control .css-1fdsijx-ValueContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-t3ipsp-control .css-hlgwow {
  padding: 0 !important;
}

/* //separator */
.css-13cymwt-control .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-t3ipsp-control .css-1u9des2-indicatorSeparator {
  display: none !important;
}

/* //arrow */
.css-13cymwt-control .css-1xc3v61-indicatorContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-t3ipsp-control .css-1xc3v61-indicatorContainer {
  padding: 0 !important;
  cursor: pointer !important;
}

.css-t3ipsp-control .css-15lsz6c-indicatorContainer {
  padding: 0 !important;
}

.css-b62m3t-container .css-1nmdiq5-menu {
  margin-top: 5px !important;
  right: 0 !important;
}

.css-b62m3t-container .css-1nmdiq5-menu .css-tr4s17-option {
  background-color: #e7e7e8 !important;
}

.css-b62m3t-container #react-select-2-listbox {
  margin-top: 5px !important;
  right: 0 !important;
}

#react-select-2-listbox {
  margin-top: 5px !important;
  right: 0 !important;
}

.color-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;

  column-gap: 38px;
  row-gap: 16px;
}

.screens-text {
  color: #5a513f;
  font-family: "open-sans";
  font-family: "open-sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 64px;
}

.chosen-tag {
  height: 24px;
  width: 24px;
  border-radius: 40px;
  position: absolute;
  bottom: 46px;
  right: 13px;
}

.holder {
  position: relative;
}

.holder .langpage-footer {
  margin-bottom: 100px;
}

.sticky-button-holder {
  z-index: 10;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  /* width: 300px; */

  height: 50px;
  border: none;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  background: white;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);

  font-family: "open-sans";
  font-family: "open-sans";

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  padding-left: 0;
}

.buttons-holder {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 1252px;
  display: flex;
  justify-content: space-between;
}

.colorpicker-outer {
  position: relative;

}

.colorpicker-outer .colorpicker {
  margin-bottom: 70px;
  /* margin-top: 24px; */
  min-height: calc(100vh - 80px - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.colorpicker-content {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-bottom: 70px;
}

.currentSvg {
  width: 393px;
  height: 393px;
  border: 1px solid #00000033;
}

.outer-color-holder {
  background: #f6f6f7;
  border: 1px solid rgba(90, 81, 63, 0.3);
  margin: 0 auto;
  margin-bottom: 8px;
  width: 393px;
}

@media (min-width: 375px) {
  .outer-color-holder {
    /* width: 335px; */
    /* margin: 0 auto; */
    /* margin-bottom: 8px; */
  }
}

.outer-color-holder .color-holder-header {
  border-bottom: 1px solid rgba(90, 81, 63, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.outer-color-holder .color-holder-header .up-icon {
  height: 6px;
  width: 12px;
  padding-right: 21px;
}

.outer-color-holder .color-holder-header .color-text {
  font-family: "open-sans";
  font-family: "open-sans";
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  color: #5a513f;
  /* padding-left: 16px; */
  padding: 13px 16px;
}

.single-color {
  display: flex;
  flex-direction: column;
  min-width: 46px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
}

.single-color .color-name {
  font-family: "open-sans";
  font-family: "open-sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.qr-screen {
  background-color: #5a513f;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-holder {
  /*  */
  /* width: 393px;
  height: 393px; */
  background-color: white;
  border: 30px white solid;
  /*  */
  margin-top: 40px;
  margin-bottom: 140px;

  text-align: center;
  background: white;
}

.qr-holder svg {
  margin-top: 40px;
}

.qr-screen-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*  */
  position: relative;
  width: 100%;
  /* margin: 0 80px; */
}

.qr-screen-title h2 {
  font-family: "open-sans-extra";
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  color: white;
}

.qr-screen-title img {
  /* height: 29.33333396911621px;
  width: 29.33333396911621px; */
  height: 36.67px;
  width: 36.67px;
  position: absolute;
  top: 37px;
  right: 45px;
}

.qr-screen-text {
  font-family: "open-sans";
  font-family: "open-sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  margin: 0 20px;
  width: 708px;
}

/*!!!!!!!!!!!!!!!!!!!!!! felosztasoknal a swiper-slide !!!!!!!!!!!!!!!!!!!!!*/
/* .shapes .swiper-slide {
  margin-bottom: 150px;
}

.motive-shapes .swiper-slide {
  margin-bottom: 150px;
} */

.wrapper {
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  /* width: 100%;
  height: 100%; */
  margin: 0 auto;
}
/* 
.shapes .chosen-tag {
  height: 24px;
  width: 24px;
  bottom: 16px;
  right: 76px;
  border-radius: 40px;
  position: absolute;
} */

/* .motive-shapes .chosen-tag {
  height: 24px;
  width: 24px;
  bottom: 36px;
  right: 46px;
  border-radius: 40px;
  position: absolute;
} */

.slide-nr {
  text-align: center;
  margin-top: 16px;
}
/* 
.swiper-wrapper {
  gap: 10px;
} */

/* .swiper-slide {
  position: relative;
} */

@media (max-width: 1400px) {
  .App {
    width: 1092px;
  }
  .header {
    max-width: 1092px;
  }
  .pajzsok {
    max-width: calc(100px + (248px * 4));
  }

  .pajzs {
    width: 248px;
  }

  .shapes {
    max-width: calc(100px + (248px * 4));
  }
  .motives {
    min-height: calc(100vh - 80px - 65px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .motive-shapes {
    max-width: calc(100px + (248px * 4));
  }

  .gotoscan-button {
    width: 340px;
  }

  .address-input {
    width: 340px;
  }

  .language-button {
    width: 339px;
  }

  /* .qr-holder {
    width: 340px;
    height: 340px;
  } */

  .currentSvg {
    width: 340px;
    height: 340px;
  }

  .outer-color-holder {
    width: 340px;
  }

  .buttons-holder {
    max-width: 1092px;
  }
}

@media (max-width: 1200px) {
  .App {
    max-width: 940px;
  }

  .header {
    max-width: 940px;
  }

  .element {
    position: relative;
    /* max-width: calc((100px + (288px * 4)) / 4); */
    width: calc((100% - (30px * 2)) / 3);
  }

  .pajzsok {
    /* max-width: calc(70px + (290px * 3)); */
    width: 100%;
    max-width: 100%;
  }

  .pajzs {
    max-width: 100%;
  }

  .shapes {
    max-width: calc(70px + (290px * 3));
  }

  .motive-shapes {
    max-width: calc(70px + (290px * 3));
  }

  .gotoscan-button {
    width: 291px;
  }

  .address-input {
    width: 291px;
  }

  .language-button {
    width: 290px;
  }

  /* .qr-holder {
    width: 291px;
    height: 291px;
  } */

  .currentSvg {
    width: 291px;
    height: 291px;
  }

  .outer-color-holder {
    width: 291px;
  }

  .buttons-holder {
    max-width: 940px;
  }
}

@media (max-width: 992px) {
  .header {
    width: calc(100vw - 40px);
  }
  .pajzsok {
    max-width: calc(64px + (290px * 3));
    margin-bottom: 70px;
  }

  .gotoscan-button {
    width: 339px;
  }

  .address-input {
    width: 339px;
  }

  .language-buttons-holder {
    width: 339px;
  }

  /* .qr-holder {
    width: 393px;
    height: 393px;
  } */

  .currentSvg {
    width: 340px;
    height: 340px;
  }

  .outer-color-holder {
    width: 340px;
  }
  .chosen-tag {
    right: calc(50% - 140px);
  }

  .buttons-holder {
    max-width: calc(100vw - 40px);
  }

  .footer {
    margin-bottom: 30px;
    margin-bottom: 0px;
  }

  .holder .langpage-footer {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .header {
    width: calc(100vw - 60px);
  }
  .gotoscan-button {
    width: 381px;
  }

  .address-input {
    width: 381px;
  }

  .language-button {
    width: 382px;
  }

  /* .qr-holder {
    width: 382px;
    height: 382px;
  } */

  .qr-screen-title img {
    right: 20px;
  }

  .qr-screen-text {
    width: 90%;
  }

  .colorpicker-content {
    flex-direction: column;
  }

  .currentSvg {
    width: 382px;
    height: 382px;
    margin: 0 auto;
  }

  .outer-color-holder {
    width: 382px;
  }
}

@media (max-width: 576px) {

  .page-title-home, 
  .page-title-pajzsok, 
  .page-title {
    font-size: 20px;
    line-height: 32px;
  }

  .home-texts {
    font-size: 16px;
    line-height: 26px;
  }
  .scan-button {
    width: calc(100vw - 40px);
  }
  .gotoscan-button {
    width: calc(100vw - 40px);
  }

  .address-input {
    width: calc(100vw - 40px);
  }

  .scan-button {
    width: 100%;
    margin: 0 20px;
  }

  .manual-input {
    width: 100%;
    margin: 0 20px;
  }
  .language-buttons-holder {
    width: 100%;
    margin: 0 20px;
  }

  .language-button {
    width: 100%;
  }

  /* .qr-holder {
    width: 100%;
    margin: 0 40px;
  } */

  .qr-screen-title h2 {
    font-size: 20px;
    line-height: 32px;
  }

  .qr-screen-title img {
    width: 20px;
    height: 20px;
    height: 29.33333396911621px;
    width: 29.33333396911621px;
    top: 18px;
    right: 20px;
  }

  .qr-screen-text {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .currentSvg {
    width: calc(100vw - (35 * 2));
    height: calc(100vw - (35 * 2));
    margin: 0 auto;
  }

  .outer-color-holder {
    width: calc(100vw - (20 * 2));
  }

  .page-title {
    margin-top: 64px;
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-width: 420px) {
}

@media (max-width: 375px) {
  */ .gotoscan-button {
    width: 100%;
  }

  .address-input {
    width: 100%;
  }

  .scan-button {
    width: 100%;
    margin: 0 20px;
  }

  .manual-input {
    width: 100%;
    margin: 0 20px;
  }
  .language-buttons-holder {
    width: 100%;
    margin: 0 20px;
  }

  .language-button {
    width: 100%;
  }

  /* .qr-holder {
    width: 100%;
    margin: 0 40px;
  } */

  .qr-screen-title h2 {
    font-size: 20px;
    line-height: 32px;
  }

  .qr-screen-title img {
    width: 20px;
    height: 20px;
    height: 29.33333396911621px;
    width: 29.33333396911621px;
    top: 18px;
    right: 20px;
  }

  .qr-screen-text {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .currentSvg {
    width: calc(100vw - (35 * 2));
    height: calc(100vw - (35 * 2));
    margin: 0 auto;
  }

  .outer-color-holder {
    width: calc(100vw - (20 * 2));
  }
}

/* .swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-wrapper{
  width: 100%
} */

.svg-div {
  width: 305px;
  height: 305x;
  margin: 0 auto;
}

.selected-color-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected-color-div p {
  margin: 0;
  text-transform: lowercase;
  padding-left: 4px;
  font-style: italic;
  font-size: 14px;
}

.swiper-slide {
  box-sizing: border-box;
  padding: 0;
  width: 305px;
}

body {
  /*Disable text selection highlight  */
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

/* .StepButton-0-2-4 {
  background-color: white !important;
  border: 2px solid #5a513f !important;

  width: 2em;
  border: none;
  cursor: pointer;
  height: 2em;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.StepButton-0-2-4 span {
  color: #5a513f;
  font-family: "open-sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
}

.StepButton-0-2-4 .StepButtonContent-d19-0-2-39.active {
  color: #5a513f;
}

.StepButton-0-2-4 .StepButton-d2-0-2-8.active {
  border: 2px solid #ba5b2d;
}

.StepButton-d2-0-2-8.active span {
  color: #ba5b2d;
  font-family: "open-sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
}

#RFS-ConnectorContainer span {
  border-color: #5a513f;
  border-top-width: 2px;
}

.ConnectorContainer-0-2-14 {
  border-color: #5a513f;
  border-top-width: 2px;
}

.StepContainer-0-2-2 .ConnectorContainer-d2-0-2-18 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
}

.StepContainer-0-2-2 .ConnectorContainer-d6-0-2-26 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
}

.StepContainer-0-2-2 .ConnectorContainer-d10-0-2-34 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
}
.StepContainer-0-2-2 .ConnectorContainer-d22-0-2-62 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
} */

/* .ConnectorContainer-d2-0-2-18 .Connector-d3-0-2-19 {
  border-color: #5a513f;
  border-top-width: 2px;
}

.Connector-0-2-15 .Connector-d7-0-2-27{
  border-color: #5a513f;
  border-top-width: 2px;
} */

.crest-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.crest-texts {
  margin-top: 40px;
  margin-bottom: 40px;
}

.crest-svg {
  border: 1px solid #c5c0c0;
}
