@font-face {
  font-family: "open-sans";
  src: local("open-sans"), url(./assets/fonts/open-sans.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "open-sans-bold";
  src: local("open-sans"),
    url(./assets/fonts/open-sans-bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "open-sans-extra";
  src: local("open-sans"),
    url(./assets/fonts/open-sans-extrabold.ttf) format("truetype");
  font-display: swap;
}

body, html {
	overscroll-behavior-x: none;
}

body iframe {
  display: none !important;
  z-index: 0 !important;
}
