.react-stepper {
  position: absolute;
  width: 100%;
  top: 131px;
  font-weight: bold;
  left: calc(50% - 174px) !important;
}

/* @media (max-width: 2560px) {
  .react-stepper {
    left: 800px;
  }
}

@media (max-width: 1920px) {
  .react-stepper {
    left: 790px;
  }
}

@media (max-width: 1440px) {
  .react-stepper {
    left: 550px;
  }
}

@media (max-width: 1024px) {
  .react-stepper {
    left: 340px;
  }
}

@media (max-width: 768px) {
  .react-stepper {
    left: 210px;
  }
}

@media (max-width: 576px) {
  .react-stepper {
    left: 110px;
  }
}

@media (max-width: 425px) {
  .react-stepper {
    left: 40px;
  }
}

@media (max-width: 375px) {
  .react-stepper {
    left: 15px;
  }
} */



.react-stepper .active span {
  color: #ba5b2d;
  font-family: "open-sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
}
.StepperContainer-0-2-1 {
  max-width: 300px;
  margin: 0 auto;
}
.react-stepper .active {
  border: 2px solid #ba5b2d !important;
}
.react-stepper .active span {
  border: none !important;
}
.react-stepper .completed span {
  display: none !important;
}
.react-stepper .completed {
  background-color: #ba5b2d !important;
  border: 2px solid #ba5b2d !important;
  background-image: url("./assets/images/check.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.react-stepper .completed :nth-of-type(1 of .active-p) #RFS-ConnectorContainer {
  border-color: #0bff9d !important;
}
.StepButton-0-2-4 {
  background-color: white !important;
  border: 2px solid #5a513f !important;

  width: 2em;
  border: none;
  cursor: pointer;
  height: 2em;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.StepButton-0-2-4 span {
  color: #5a513f;
  font-family: "open-sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
}

.StepButton-0-2-4 .StepButtonContent-d19-0-2-39.active {
  color: #5a513f;
}

.StepButton-0-2-4 .StepButton-d2-0-2-8.active {
  border: 2px solid #ba5b2d;
}

#RFS-ConnectorContainer span {
  border-color: #5a513f;
  border-top-width: 2px;
}

.StepContainer-0-2-2 .ConnectorContainer-d2-0-2-18 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
}

.StepContainer-0-2-2 .ConnectorContainer-d6-0-2-26 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
}

.StepContainer-0-2-2 .ConnectorContainer-d10-0-2-34 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
}
.StepContainer-0-2-2 .ConnectorContainer-d22-0-2-62 {
  top: calc((2em - 1px) / 2);
  left: calc((-50% + 1em));
  right: calc((50% + 1em));
  position: absolute;
}
*/ .ConnectorContainer-d2-0-2-18 .Connector-d3-0-2-19 {
  border-color: #5a513f;
  border-top-width: 2px;
}

.Connector-0-2-15 .Connector-d7-0-2-27 {
  border-color: #5a513f;
  border-top-width: 2px;
}

/* .react-stepper .completed .Connector-0-2-15 {  
  border-color: green !important;
}
#RFS-Connector{  
  border-color: #ba5b2d !important;
} */